.page-link.active {
    position: relative;
    display: block;
    line-height: 1.25;
    color: #fff;
    border: 1px solid #dee2e6;
    background-color: #7a6eff;
}

.pagination li a {
    border-radius: 50%;
}
.Comment {
    width: 100%;
}

.UserCm {
    width: 100%;
    float: right;
    margin-bottom: 25px;

}


.UserCm img {
    width: 8%;
    float: left;
    margin-left: 10px;
    border-radius: 10px;
    border: 2px solid #43f;
}

.CmBox {
    width: 90%;
    border-radius: 10px;
    /* border: 1px solid #333; */
    float: left;
    padding: 10px;
    box-shadow: 0px 0px 5px -2px #7a7a7a;

}

.Cmtop {
    float: right;
    width: 100%;
    padding: 5px;
    height: 50px;
}

.Cmtop h3 {
    float: right;
    font-size: 16px;
    
}

.Cmtop time {
    float: left;
    font-size: 16px;
    
}

.Cmtop p {
    float: left;
    font-size: 16px;
    
    margin-top: -3px;
    margin-right: 10px;
    cursor: pointer;
}

.CmBottom {
    width: 100%;
    float: right;
    padding: 25px 10px;
    box-sizing: border-box;
}

.CmBottom p {
    width: 100%;
    padding: 5px;
    font-size: 17px;
    
    text-align: justify;
}


.Ans {
    width: 100%;
}


.Ans img {
    width: 8%;
    float: left;
    margin-left: 10px;
    border-radius: 10px;
    border: 2px solid #43f;
}

.AnsBox {
    width: 80%;
    border-radius: 10px;
    /* border: 1px solid #333; */
    float: left;
    padding: 10px;
    box-shadow: 0px 0px 5px -2px #7a7a7a;
}

.Anstop {
    float: right;
    width: 100%;
    padding: 5px;
    height: 50px;
}

.Anstop h3 {
    float: right;
    font-size: 16px;
    
}

.Anstop time {
    float: left;
    font-size: 16px;
    
}

.AnsBottom {
    width: 100%;
    float: right;
    padding: 25px 10px;
    box-sizing: border-box;
}

.AnsBottom p {
    width: 100%;
    padding: 5px;
    font-size: 17px;
    
    text-align: justify;

}

/* .Paginatebox{ */
    /* padding-bottom: 20px; */
/* } */

.Paginate {
    position: absolute;
    top: 5px;
    left: 5px;
}

@media (min-width:1500px) {
    .Ans img {
        width: 100px;
    }

    .UserCm img {
        width: 100px;
    }
}

@media (max-width:980px) {
    .Ans img {
        width: 10%;
    }

    .UserCm img {
        width: 10%;
    }

    .AnsBox {
        width: 75%;
    }

    .CmBox {
        width: 85%;
    }

    .CmBottom, .AnsBottom {
        padding: 15px 10px;
    }

    .CmBottom p, .AnsBottom p {
        font-size: 16px;
    }
}

@media (max-width:680px) {
    .Ans img, .UserCm img {
        width: 14%;
    }

    .AnsBox {
        width: 69%;
    }

    .CmBox {
        width: 79%;
    }

    .Cmtop, .Anstop {
        height: 40px;
    }

    .Cmtop h3, .Anstop h3 {
        font-size: 13px;
    }

    .Cmtop p, .Cmtop time, .Anstop time {
        font-size: 13px;
    }

    .CmBottom, .AnsBottom {
        padding: 5px 10px;
    }

    .CmBottom p, .AnsBottom p {
        font-size: 14px;
    }

}

@media (max-width:575px) {
    .Ans img, .UserCm img {
        width: 18%;
    }

    .CmBottom p, .AnsBottom p {
        font-size: 13px;
    }
    .Paginatebox{
        padding-bottom: 70px;
    }
    
    .Paginate {
        top: 60px;
        left: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:420px) {
    .Ans img, .UserCm img {
        display: none;
    }

    .AnsBox, .CmBox {
        width: 100%;
    }
    .Paginatebox{
        padding-bottom:20px;
    }
    
    .Paginate {
        top: 105px;
    }

}
@font-face {
    font-family: 'PublicFont';
     src:  url('../fonts/Fonts/Vazir.ttf');

}

/*  
@font-face {
    font-family: 'byekan';
     src:  url('../../Assets/Font/BYekan.ttf');

}
@font-face {
    font-family: 'btitr';
     src:  url('../../Assets/Font/BTitrBd.ttf') ;

}
@font-face {
    font-family: 'bnazanin';
     src:  url('../../Assets/Font/BNazanin.ttf') ;

}
@font-face {
    font-family: 'bmehr';
     src:  url('../../Assets/Font/BMehrBd.ttf') ;

}
@font-face {
    font-family: 'bhoma';
     src:  url('../../Assets/Font/BHoma.ttf');

}
@font-face {
    font-family: 'tahoma';
     src:  url('../../Assets/Font/tahoma.ttf') ;

}

@font-face {
    font-family: 'buff';
     src:  url('../../Assets/Font/Buff.ttf') ;

}
 */

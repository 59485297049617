.Loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(43, 43, 43, 0.7);
    z-index: 900;
    
}
.LoaderHide{
    display: none;
}
.Loader_Box{
    width: 100%;
    height: 100%;
    position: relative;
}
.Loader_Box .container{
    width: 200px;
    position:absolute;
    top:40%;
    left: 50%;
    margin-left: -100px;
}

.Loader img{
    width: 65px;
    height: 65px;
display: block;
margin: 0 auto;

}
.Loader p{
    height: 50px;
float: right;
margin: 10px auto;
width: 100%;
text-align: center;
font-family: bhoma;
color: #fff;
}

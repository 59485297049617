.GSlider {
    width: 100%;
    height: 365px;
    margin: 0;
    position: relative;
    border-radius: 25px;
}

.SvgImg {
    position: absolute;
    top: 0;
    right: 8px;
}

.SliderTitr {
    position: absolute;
    width: 124px;
    top: 5px;
    right: 130px;
    font-size: 17px;
    text-align: center;
    line-height: 45px;
    z-index: 200;
    color: #333;
    font-family: PublicFont;
}


.SliderMore {
    position: absolute;
    top: 5%;
    left: 15px;
    height: 90%;
    width: 60px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.SliderMore p {
    height: 60px;
    width: 100%;
    padding: 5px;
    text-align: center;
    color: #fafafa;
    font-family: PublicFont;
    font-size: 14px;
}

.SliderMore:hover {
    transform: translateX(-5px);
    cursor: pointer;
    background: rgba(0, 0, 0, 0.5);
}

.SliderStaticImg {
    position: absolute;
    bottom: -15px;
    right: 110px;
}


.SliderShowMore {
    display: none;
    z-index: 200;
}

.SliderShowMore p {
    color: #fff;
    font-size: 14px;
    font-family: PublicFont;
}


.SliderBox {
    float: left;
    width: 65%;
    height: 295px;
    margin: 0;
    display: flex;
    margin-left: 70px;
    margin-top: 40px;
}

.holder {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.SliderItem {
    height: 280px;
    margin-top: 10px;
}

.ItemHolder {
    float: left;
    height: 100%;
    width: 90%;
    background: #fff;
    border-radius: 20px!important;
    overflow: hidden;
    outline: none!important;
    border: none!important;
    box-shadow: none!important;
}

.ItemHolder:hover .SliderItemHover {
    top: 0;
    left: 0;
}

.ImageBox {
    width: 100%;
    height: 170px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

.ImageBox img {
    height: 100%;
    margin: 15px auto;
    display: block;
}


.SliderItemHover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 20px 20px 0 0;
    top: -110%;
    left: 0%;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;

}

.SliderItemHover p {
    font-family: PublicFont;
    color: #fff;
    font-size: 15px;
    margin-top: 10px;
    padding: 5px;
    border-radius: 8px;
    background-color: rgba(12, 1, 104, 0.5);
    transition: 0.3s;
}

.SliderItemHover p:hover {
    cursor: pointer;
    background-color: rgba(12, 1, 104, 0.8);
}


.information {
    width: 100%;
    height: 60px;
}

.information h2 {
    width: 100%;
    font-family: PublicFont;
    font-size: 16px;
    line-height: 20px;
    padding-top: 10px;
    text-align: center;
    color: #555;
}

.information h3 {
    width: 100%;
    line-height: 20px;
    font-family: PublicFont;
    font-size: 15px;
    text-align: center;
    color: #666;
}


.footer {
    width: 95%;
    height: 50px;
    display: flex;
    margin: auto;
    direction: rtl;
    justify-content: space-between;
    align-items: center;
}



@media (max-width:1200px) {
    .SliderStaticImg {
        display: none;
    }

    .GSlider {
        height: 430px;
    }

    .SliderBox {
        float: right;
        margin-top: 85px;
        width: 90%;
    }
}

@media (max-width:820px) {
    .SliderMore {
        display: none;
    }

    .SvgImg {
        display: none;
    }

    .SliderBox {
        margin-top: 60px;
        width: 100%;
    }

    .SliderTitr {
        width: 124px;
        top: 5px;
        right: 30px;
        font-size: 18px;
        line-height: 45px;
        color: #fff;
    }

    .SliderShowMore {
        display: block;
        position: absolute;
        bottom: 10px;
        left: 20px;
    }
}

@media (max-width: 680px) {

    .information h2 {
        font-size: 12px;
        line-height: 15px;
        padding-top: 10px;
    }

    .information h3 {
        line-height: 15px;
        font-size: 12px;

    }

    .footer span {
        font-size: 12px !important;
        padding: 5px !important;
    }

}

@media (max-width:400px) {
    .GSlider {
        height: 365px;
    }

    .SliderBox {
        height: 260px;
    }

    .SliderItem {
        height: 240px;
        padding-bottom: 5px;
    }

    .footer {
        display: none;
    }
}
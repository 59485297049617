.Background{
    width: 100%;
    height: 800px;
    /* background: url(./../../../Assets//UserAssets/UserContent/Auth/backGround.png); */
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    position: relative;
}
.backHome{
    width: 110px;
    height: 60px;
    position: fixed;
    top: 50px;
    right: 50px;
    transition: 0.5s;
}
.backHome:hover{
transform: translateX(8px);
cursor: pointer;
}
.Content{
    width: 350px;
    min-height: 300px;
    margin-top: 120px;
}
.Header{
    width: 300px;
    height: 180px;
    /* background:  #B5AFFF; */
    background:  #9d00d1;
    position: absolute;
    top: -50px;
    right: 50%;
    margin-right: -150px;
    box-shadow: 1px 1px 15px 1px #8c8c8c;
    animation-name: anim;
    animation-duration: 1s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    animation-fill-mode: forwards;
}
.Header p {
width: 100%;
height: 40px;
font-family: PublicFont;
font-size : 21px ; 
/* color: #990b0b; */
color: #fff;
text-align: center;
    float: right;
    line-height: 30px;
    transition: 0.5s;
}
/* .Header p:hover {
    color: #000275;
    transform: scale(1.03);
} */

.logo{
    width: 100%;
    height: 100px;
    float: right;
}
.logo img{
width: 80px;
margin: 10px auto;
display: block;
transition: 0.5s;
}
.logo img:hover{
transform: scale(1.03);

}
.ContactUs{
    width: 100%;
    float: right;
    height: 40px;
    display: flex;
    justify-content: center;
    margin-top: -10px;
}
.ContactUs img{
    width: 25px;
    margin: 0 5px;
    transition: 0.5s;
} 
.ContactUs img:hover{
transform: scale(1.1);
}
.HomeImg{
display: none;
}

.HomeButton{
    width: 90%;
    float: right;
    margin-right: 5%;
    margin-bottom: 30px;
    font-family: PublicFont;
}

  @keyframes anim {
    0%   {  top:0px;}
    40%  { top:60px;}
    55%  { top:50px;}
    65%  {top:60px;}
    85%  {top:50px;}
    100% {top:50px;}
  }

@media screen and (max-width:780px) {
    .HomeImg{
        display: block;
    }
    .backHome{
        display: none;
    }
}

.BookSlider-1 {
    background: linear-gradient(71.52deg, #c0dce9 19.37%, #95c4d7 95.95%);
}

.BookSlider-2 {
    background: #2d5cd7;
}

.BookSlider-3 {
    background: #acc32e;
}

.BookSlider-4 {
    background: #552ec3;
}

.BookSlider-5 {
    background: #c3ad2e;
}

.BookSlider-6 {
    background: #a52ec3;
}

.BookSlider-7 {
    background: #2ec33a;
}

.BookSlider-8 {
    background: #2e6fc3;
}

.BookSlider-9 {
    background: #c32e85;
}

.BookSlider-10 {
    background: #c32e53;
}

.BookSlider-11 {
    background: #c3942e;
}

.BookSlider-12 {
    background: #2ec39e;
}

.BookSlider-13 {
    background: #8fc32e;
}

.BookSlider-14 {
    background: #672ec3;
}

.BookSlider-15 {
    background: #c32ec3;
}






.slick-arrow {
    display: none !important;

}
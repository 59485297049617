.VideoTage {
    position: relative;
    width: 110%;
    margin-right: -5%;
}

.MainSlider {
    position: absolute;
    z-index: 2;
    width: 400px;
    height: 180px;
    background-color: rgba(0, 0, 0, 0.5);
    top: 200px;
    left: -400px;
    border-radius: 10px;
    padding: 10px 10px;

    animation-name: anim;
    animation-duration: 1s;
    animation-delay: 3s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    animation-fill-mode: forwards;
}

.Titr {
    font-family: PublicFont;
    text-align: right;
    width: 100%;
    line-height: 50px;
    color: #fff;
    font-size: 20px;
    padding: 2px 8px;
}

.SearchImg {
    height: 45px;
    margin-top: -7px;
    margin-left: 5px;
}
.input{
    box-shadow: 0px 0px 5px -1px rgb(21, 24, 177);
}

@keyframes anim {
    0% {
        left: -400px;
    }

    100% {
        left: 80px;
    }
}

@keyframes anim1 {
    0% {
        left: -400px;
    }

    100% {
        left: 50%;
    }
}

@media (max-width: 780px) {

    .MainSlider {
        position: absolute;
        z-index: 2;
        width: 300px;
        height: 180px;
        top: 100px;
        left: -400px;
        margin-left: -150px;
        border-radius: 10px;
        padding: 7px 7px;
        animation-name: anim1;


    }


}


@media (max-width: 580px) {

    .MainSlider {
        width: 300px;
        height: 145px;
        top: 50px;
        left: -400px;
        margin-left: -150px;
        animation-name: anim1;


    }

    .Titr {
        line-height: 35px;
        font-size: 16px;
        padding: 2px 8px;
    }
    
    .SearchImg {
        height: 30px;
        margin-top: -7px;
        margin-left: 5px;
    }

}
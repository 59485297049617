.img {
    width: 100%;
    height: 500px;
    margin: 50px 0;
    border-radius: 5px;
}
.Box{
    margin-top: 50px;
}
.Baner{
    position: relative;
    width: 100%;
    background:rgb(6, 204, 138);
    border-radius: 12px;
    transition: 0.3s;
    overflow: hidden;
    margin-top: 10px;
}
.Baner:hover {
transform: scale(1.02);
cursor: pointer;
}
.Baner img{
    width: 100%;
    height: 100%;
}
.Baner p{
position: absolute;
bottom: -10px;
left: 10px;
padding: 7px 12px;
background-color: #fff;
border-radius: 13px;
font-family: PublicFont;
font-size: 12px;
font-weight: bold;
}

 .single-article {
     font-family: PublicFont;
 }
 
 .single-article-card {
     margin-top: 6%;
 }
 
 .header-article-row {
     display: flex;
     justify-content: space-between;
     align-items: baseline;
 }
 
 .avatar {
     border: none;
 }
 
 .avatar span {
     border: none;
     border-radius: 50%;
 }
 
 .header-article-row .reveiw {
     display: flex;
     align-items: baseline;
 }
 
 .header-article-row .comments {
     margin-left: 6px;
 }
 
 .header-article-row .reveiw>span:first-child,
 .header-article-row .comments>span:first-child {
     margin-left: 6px;
     margin-top: 2px;
 }
 
 .header-article-row span {
     color: #4534fd;
 }
 
 .single-article-card-body>div:last-child {
     margin-top: 2%;
     display: flex;
     flex-direction: column;
 }
 
 .card-img {
     display: flex;
     justify-content: center;
     align-items: center;
 }
 
 .card-img img {
     object-fit: fill;
     display: block;
     margin: auto;
     width: 80%;
     height: 300px;
 }
 
 .empty-avatar {
     width: 100px;
     height: 100px;
     border-radius: 50%;
 }
 
 @media (max-width:768px) {
     .card-img img {
         width: 100%;
         height: 250px;
     }
 }
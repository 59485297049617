.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
    font-family: PublicFont!important;
}
.FixBookListHeight{
    height: 220px!important;
    margin: 0 auto;
    display: block;
}
.FixBookListHeightL{
    height: 260px!important;
    margin: 0 auto;
    display: block;
}
.FixBookListHeight-s{
    height: 165px!important;
    margin: 0 auto;
    display: block;
}

.FixBookListHeightMs{
    height: 60px!important;
    margin: 0 auto;
    display: block;
}

.product-sidebar {
width: 100%!important;
}
.customizer-links{
    display: none!important;
}
.ImportantFont {
    font-family: PublicFont !important;
}
    
.TxtJustify {
    text-align: justify !important;
    font-family: PublicFont;
    line-height: 40px;
}

.CommentUl {
    padding-left: 0px!important;
}
.FixedWidth{
  width: 100%!important;
}

[dir="rtl"] .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body {
display: block!important;
}

@media (max-width: 1366px) {
.ChatWidth{
    flex: 0 0 100%!important;
    max-width: 100%!important;
    overflow: hidden;
}
}

@media (max-width: 580px) {
.resin{
    font-size: 14px!important;
    height: 30px!important;
}
.UserPanelUl > ul{
    left: 0!important;
}
}
@media (max-width: 767.98px){
[dir="rtl"] .page-wrapper.compact-wrapper .nav-right .nav-menus {
    margin-left: 0px!important;
}
.profile-media .media-body {
    display: block!important;
}
.AbMaxHeight{
    max-height: 300px!important;
}
.NotShowLogin{
    display: none!important;
}
}




@import url('https://fonts.googleapis.com/css?family=Mukta:700');

$bg: #f3f8fa;
$white: #fff;
$black: #282936;

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {
  transition: $property $duration $ease;
}



.buttonss {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  &.learn-more {
    width: 10rem;
    height: auto;
    .circle {
      @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
      position: relative;
      display: block;
      margin: 0;
      width: 3rem;
      height: 3rem;
      background: #0d6efd;
      border-radius: 1.625rem;
      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background: $white;
        &.arrow {
          @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
          left: 0.625rem;
          width: 1.125rem;
          height: 0.125rem;
          background: none;
          &::before {
            position: absolute;
            content: '';
            top: -0.25rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            border-top: 0.125rem solid #fff;
            border-right: 0.125rem solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }
    .button-text {
      @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.75rem 0;
      margin: 0 0 0 1.85rem;
      color: $black;
      font-weight: 700;
      line-height: 1.6;
      text-align: center;
      text-transform: uppercase;
    }
  }
  &:hover {
    .circle {
      width: 100%;
      .icon {
        &.arrow {
        background: $white;
        transform: translate(1rem, 0);
        }
      }
    }
    .button-text {
      color: $white;
    }
  }
}

@supports (display: grid) {
  #container {
    grid-area: main;
    align-self: center;
    justify-self: center;
    direction: ltr;
    margin-bottom: 30px;
  }
}



@import 'assets/scss/app.scss'

.Baner-4 {
    height: 150px;
}
.Baner-2 {
    height: 300px; 
}
.Baner-3 {
    height: 200px;
}
.Baner-1 {
    height: 420px;
}

@media screen and (min-width:1500px) {
    .Baner-4 {
        height: 250px;
    }
    .Baner-2 {
        height: 400px; 
    }
    .Baner-3 {
        height: 300px;
    }
    .Baner-1 {
        height: 520px;
    }
    
}

@media screen and (max-width:880px) {

    .Baner-4 {
        height: 130px;
    }
    .Baner-2 {
        height: 270px; 
    }
    .Baner-3 {
        height: 170px;
    }
    .Baner-1 {
        height: 370px;
    }

}
@media screen and (max-width:680px) {
    .Baner-4 {
        height: 100px;
    }
}
@media screen and (max-width:575px) {

    .Baner-4 {
        height: 230px;
    }
    .Baner-2 {
        height: 230px; 
    }
    .Baner-3 {
        height: 230px;
    }
    .Baner-1 {
        height: 230px;
    }

}
.Audio {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 20px;
}

.TitrImg {
    width: 40px;
    margin: 5px 7px;
}

.PdfTitrImg {
    width: 30px;
    margin: 1px 7px;
    margin-left: 3px;
}

.ForgetPassword {
    width: 100%;
    /* height: 500px; */
    display: block;
    float: right;
    /* background: #fff; */
    border-radius: 10px;
    box-shadow: -1px 1px 10px 1px #8c8c8c;
}

.ForgetPassword h3 {
    margin-top: 130px;
    font-family: PublicFont;
    font-size: 22px;
    color: #444;
    width: 100%;
    text-align: right;
    height: 20px;
    line-height: 20p;
    margin-right: 8px;
}

.input {
    width: 100%;
    margin-top: 10px;
}

.input input {
    width: 90%;
    margin-right: 5%;
    height: 30px;
    border: none;
    outline: none;
    float: right;
    margin-top: 20px;
    text-align: center;
    padding: 5px 10px;
    box-sizing: border-box;
    font-family: PublicFont;
    font-size: 16px;
    color: #444;
    border-bottom: 2px solid rgb(165, 165, 165)!important;
    transition: 0.5s;
}

.input input:focus {
    /* border-bottom: 2px solid rgb(0, 20, 196); */
    border: none!important;
}

.button {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    float: right;
    margin-top: 20px;

}

.button button {
    width: 130px;
    height: 30px;
    border: none;
    outline: none;
    margin: 0 5px;
    font-family: PublicFont;
    font-size: 15px;
    /* box-shadow: -1px 1px 5px 0px #666; */
    border-radius: 3px;
    color: #fff;
    background: rgb(0, 13, 190);
    transition: 0.5s;
}

.button button:hover {
    transform: translateY(-4px);

}

.ForgetPassword p {
    width: 100%;
    height: 40px;
    color: rgb(98, 87, 243);
    text-align: center;
    float: right;
    font-family: PublicFont;
    transition: 0.5s;
    margin-top: 30px;
}

.ForgetPassword p:hover {
    transform: translateY(-3px);

}

.ResetPass {
    float: right;
    width: 90%;
    margin-right: 5%;
}
a {
    text-decoration: none !important;
}

.HoverRooms {
    border-top: 2px solid #eee;
    padding: 15px 0;
}

.HoverRooms:hover {
    border-left: 2px solid rgb(18, 0, 180);
    cursor: pointer;
}

.ArticleM strong {
    font-family: PublicFont !important;
    font-weight: normal !important;
    font-size: 19px !important;
    text-align: justify !important;
}

.bookHeader {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: PublicFont;
    font-size: 18px;
    text-align: center;
    background: url('./assets/images/Landing/arrow.png') 98% 5px #7961ed;
    background-size: 50px;
    background-repeat: no-repeat;
    border-radius: 10px;
    color: #fff;
    pad: 10px;
    box-sizing: border-box;
    transition: 0.3s;
}

.bookHeader:hover {
    background-position-x: 96%;
    cursor: pointer;
}

.CallUs {
    font-family: PublicFont;
    font-size: 20px;
    margin-top: 12px;
    color: #333;
}

.CurPoi {
    cursor: pointer;
}

.NotTrue {
    display: none !important;
}

.newMessage {
    background-color: red;
    height: 25px;
    line-height: 25px;
    padding: 0px 10px;
    position: absolute;
    color: #fff;
    top: 50%;
    left: 5px;
    margin-top: -12.5px;
    border-radius: 8px;
    font-family: PublicFont;
}

.ViDbackground {
    background-color: #4534fd;
    padding: 5px 10px;
    border-radius: 8px;
    color: #fff !important;
}

.NoResult {
    width: 100%;
    height: 100px;
    line-height: 95px;
    border-radius: 5px;
    font-family: PublicFont;
    font-size: 17px;
    line-height: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px;
    box-shadow: 0px 0px 8px 0px rgb(153, 153, 153);
}

.ReplyIco {
    color: #444 !important;
    cursor: pointer;
}

.PublicFont {
    font-family: PublicFont !important;
    text-align: right !important;
    padding-right: 8px !important;
    direction: rtl !important;
}

.InputSetting {
    font-family: PublicFont !important;
    text-align: center !important;
    direction: rtl;
}

.ToolBox {
    position: relative;
}

.ToolBox .ToolBoxText {
    visibility: hidden;
    width: 140px;
    background-color: black;
    font-size: 14px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -40px;
    left: 50%;
    margin-left: -70px;
    font-family: PublicFont;
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
}

.ToolBox:hover .ToolBoxText {
    visibility: visible;
    opacity: 1;
}

.FooterCR {
    background: #333;
}

.FooterCRText {
    color: #fff;
    font-family: PublicFont;
    font-size: 15px;
}

.FilterSearch {
    text-align: center !important;
}

.LogeOut {
    width: 20px;
    margin-right: 20px;
    transition: 0.5s;
}

.LogeOut:hover {
    transform: rotateY(-360deg);
}

.Font-14 {
    font-size: 13px !important;
}

.backtochat {
    position: absolute;
    left: 50px;
    top: 30px;
    font-size: 16px;
    font-family: PublicFont;
    transition: 0.3s;
}

.backtochat:hover {
    transform: scale(1.03);
    cursor: pointer;
}

.SokhanZiba {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    line-height: 30px;
    text-align: center !important;
    font-size: 18px;
    font-family: PublicFont;
}

.ContactUsInfo {
    width: 100%;
    position: relative;
    height: 330px;
    margin-top: 50px;
}

.ContactUsInfo img {
    display: block;
    width: 400px;
    border-radius: 5px;
    border: 3px solid rgb(255, 255, 255);
    /* outline: 3px solid #666; */
}

.ContactUsUser {
    width: 100%;
    height: 300px;
    position: absolute;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ContactUsUser small {
    font-family: PublicFont !important;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 18px !important;
    line-height: 30px;
}

.AboutMeAf {
    font-family: PublicFont !important;
    font-size: 16px !important;
    text-align: right;
    width: 100%;
    line-height: 30px;
    display: block;
}

.AboutMeEn {
    font-family: PublicFont !important;
    font-size: 16px !important;
    text-align: left;
    width: 100%;
    line-height: 30px;
    display: block;
}

.AboutImg {
    width: 100%;
    margin-top: 15px;
}

.AboutImg img {
    width: 100%;
}

.MatnBahal {
    width: 100%;
    pad: 20px 5px;
}

.MatnBahal small {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-family: PublicFont;
    line-height: 24px;
    display: block;
}

.profileRemove {
    width: 25px;
    position: absolute;
    bottom: 8px;
    left: 15px;
    transition: 0.3;
}

.profileRemove:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.MatnBahal small:nth-child(2) {
    color: rgb(23, 143, 23);
}

.downloadBox {
    width: 100%;
    height: 50px;
    display: block;
}

.downloadBox span {
    line-height: 50px;
}

.buttonLogeOut {
    position: relative;
    display: block;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 10px 30px;
    background: rgba(68, 51, 255, 0.952);
    color: rgba(255, 255, 255, 0.952);
    cursor: pointer;
    transition: 0.3s;
    font-family: PublicFont;
    font-size: 17px;
    font-weight: normal;
    float: left;
    margin-top: 1px;
    text-align: center;
}

.buttonLogeOut:hover {
    transform: scale(1.02);
}

@-webkit-keyframes orbit {
    0% {
        -webkit-transform: rotateY(90deg) rotate(0deg);
    }
    60% {
        -webkit-transform: rotateY(90deg) rotate(-180deg);
    }
    100% {
        -webkit-transform: rotateY(90deg) rotate(-360deg);
    }
}

@-moz-keyframes orbit {
    0% {
        -moz-transform: rotateY(90deg) rotate(0deg);
    }
    60% {
        -moz-transform: rotateY(90deg) rotate(-180deg);
    }
    100% {
        -moz-transform: rotateY(90deg) rotate(-360deg);
    }
}

@keyframes orbit {
    0% {
        -webkit-transform: rotateY(90deg) rotate(0deg);
        -moz-transform: rotateY(90deg) rotate(0deg);
        -ms-transform: rotateY(90deg) rotate(0deg);
        -o-transform: rotateY(90deg) rotate(0deg);
        transform: rotateY(90deg) rotate(0deg);
    }
    60% {
        -webkit-transform: rotateY(90deg) rotate(-180deg);
        -moz-transform: rotateY(90deg) rotate(-180deg);
        -ms-transform: rotateY(90deg) rotate(-180deg);
        -o-transform: rotateY(90deg) rotate(-180deg);
        transform: rotateY(90deg) rotate(-180deg);
    }
    100% {
        -webkit-transform: rotateY(90deg) rotate(-360deg);
        -moz-transform: rotateY(90deg) rotate(-360deg);
        -ms-transform: rotateY(90deg) rotate(-360deg);
        -o-transform: rotateY(90deg) rotate(-360deg);
        transform: rotateY(90deg) rotate(-360deg);
    }
}

.button65456 {
    position: relative;
    display: block;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;
    padding: 10px 30px 10px 70px;
    background: rgba(68, 51, 255, 0.952);
    color: rgba(255, 255, 255, 0.952);
    cursor: pointer;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
    font-family: PublicFont;
    font-size: 17px;
    font-weight: normal;
    float: left;
    margin-top: 1px;
}

.button65456:hover {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
    background: rgb(68, 51, 255);
    color: rgb(255, 255, 255);
}

.button65456:hover>span {
    -webkit-transition: all 800ms ease-in-out;
    -moz-transition: all 800ms ease-in-out;
    transition: all 800ms ease-in-out;
    -webkit-transform: rotateY(90deg) rotate(-360deg);
    -moz-transform: rotateY(90deg) rotate(-360deg);
    -ms-transform: rotateY(90deg) rotate(-360deg);
    -o-transform: rotateY(90deg) rotate(-360deg);
    transform: rotateY(90deg) rotate(-360deg);
}

.button65456>span {
    width: 80px;
    display: block;
    position: absolute;
    left: 0;
    top: -40px;
    bottom: -40px;
    border-radius: 50%;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateY(90deg) rotate(0deg);
    -moz-transform: rotateY(90deg) rotate(0deg);
    -ms-transform: rotateY(90deg) rotate(0deg);
    -o-transform: rotateY(90deg) rotate(0deg);
    transform: rotateY(90deg) rotate(0deg);
}

.button65456>span>span {
    position: absolute;
    background: rgb(255, 255, 255);
    width: 13px;
    height: 15px;
    left: 0;
    top: 50px;
    -webkit-transform: rotateY(-90deg);
    -moz-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
}

.button65456>span>span::before {
    position: absolute;
    content: "";
    top: 100%;
    left: -2px;
    margin-left: -7px;
    border-top: 16px solid rgb(255, 255, 255);
    border-bottom: 0 solid transparent;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
}

@media screen and (max-width:980px) {
    .ContactUsInfo {
        height: 170px;
    }
    .ContactUsUser {
        height: 170px;
        display: block;
    }
    .ContactUsInfo small {
        text-align: center;
        display: block;
        margin-top: 40px;
        width: 100%;
    }
}

@media screen and (max-width:780px) {
    .FilterSearch {
        width: 100% !important;
    }
    .SokhanZiba {
        height: 80px;
        line-height: 30px;
        font-size: 16px;
    }
}

@media screen and (max-width:580px) {
    .NoResult {
        font-size: 14px;
    }
    .SokhanZiba {
        height: 100px;
        line-height: 30px;
        font-size: 15px;
    }
    .ContactUsUser small {
        font-size: 16px !important;
    }
    .AboutMeAf,
    .AboutMeEn {
        font-size: 14px !important;
    }
}

@media screen and (max-width:480px) {
    .NoResult {
        font-size: 12px;
    }
    .SokhanZiba {
        height: 120px;
        line-height: 30px;
        font-size: 12px;
    }
    .ContactUsUser small {
        font-size: 12px !important;
    }
    .AboutMeAf,
    .AboutMeEn {
        font-size: 11px !important;
    }
    .ContactUsUser {
        position: relative !important;
        background: none !important;
        display: block;
        width: 100%;
    }
    .ContactUsUser small {
        color: #333;
        width: 100%;
        text-align: left;
        float: left;
    }
    .ContactUsInfo img {
        width: 140px;
        float: left;
        border-color: #444;
    }
}


/* editor placeholder*/

.jodit-placeholder {
    visibility: hidden;
    position: relative;
}

.jodit-placeholder:after {
    visibility: visible;
    position: absolute;
    top: 15px;
    right: 5px;
    content: 'متن خود را در این قسمت وارد کنید...';
}


/* books card */

.product-box {
    min-height: 22rem;
}

.empty-author {
    margin-top: 26px;
}